import React from 'react';
import { Grid, _ } from 'gridjs-react';
import {currencyFormat} from "../../helpers/api_helper";
const data2 = [
    [1, 12.0, currencyFormat(3110.0), currencyFormat(259.2), 94.0],
    [2, 53.0, currencyFormat(40925.0), currencyFormat(386.1), 109.6],
    [3, 18.0, currencyFormat(18665.0), currencyFormat(345.6), 103.0],
    [4, 25.0, currencyFormat(29545.0), currencyFormat(295.5), 129.1],
    [5, 11.0, currencyFormat(16705.0), currencyFormat(303.7), 157.4],
    [6, 11.0, currencyFormat(21405.0), currencyFormat(324.3), 163.0],
    [7, 5.0, currencyFormat(10030.0), currencyFormat(286.6), 123.9],
    [8, 3.0, currencyFormat(535.0), currencyFormat(22.3), 131.9],
];

// Card Table
const CardTableExample = () => {
    return (
        <React.Fragment>
            <Grid
                data={data2}
                columns={["Covers", "Check Count", "Revenue", "Avg Check Price", "Turn Times"]}
                sort={true}
                pagination={{ enabled: true, limit: 5, }}
            />
        </React.Fragment>
    );
};

export { CardTableExample};
