import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import VerifyEmailSaga from "./auth/verifyemail/saga";
import UpdatePasswordSaga from "./auth/updatepassword/saga";
import VerifyCodeSaga from "./auth/verifycode/saga";
import ResetPasswordSaga from "./auth/resetpassword/saga";
import UserOutletSaga from "./userOutletGroup/saga";
import DashboardSaga from "./dashboard/saga";
import MenuOptimizationSaga from "./menu-optimization/saga";
export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(VerifyEmailSaga),
    fork(UpdatePasswordSaga),
    fork(VerifyCodeSaga),
    fork(ResetPasswordSaga),
    fork(UserOutletSaga),
    fork(DashboardSaga),
    fork(MenuOptimizationSaga),
  ]);
}