import {
  GET_KIP_FILTER_API_DATA,
  KIP_FILTER_API_RESPONSE_SUCCESS,
  GET_KIP_WIDGET_API_DATA,
  KIP_WIDGET_API_RESPONSE_SUCCESS,
  GET_KIP_FORECAST_API_DATA,
  KIP_FORECAST_API_RESPONSE_SUCCESS,
  GET_KIP_SERVER_PERFORMANCE_API_DATA,
  KIP_SERVER_PERFORMANCE_API_RESPONSE_SUCCESS,
  GET_KIP_FORECAST_HEATMAP_API_DATA,
  KIP_FORECAST_HEATMAP_API_RESPONSE_SUCCESS
} from "./actionType";

export const getKIPFilterData = (data) => ({
  type: GET_KIP_FILTER_API_DATA,
  payload: data
});

export const dashboardApiFilterSuccess = (actionType, data) => ({
  type: KIP_FILTER_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const getKIPWidgetData = (data) => ({
  type: GET_KIP_WIDGET_API_DATA,
  payload: data
});

export const dashboardApiWidgetSuccess = (actionType, data) => ({
  type: KIP_WIDGET_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const getKIPForecastData = (data) => ({
  type: GET_KIP_FORECAST_API_DATA,
  payload: data
});

export const dashboardApiForecastSuccess = (actionType, data) => ({
  type: KIP_FORECAST_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const getKIPServerPerformanceData = (data) => ({
  type: GET_KIP_SERVER_PERFORMANCE_API_DATA,
  payload: data
});

export const dashboardApiServerPerformanceSuccess = (actionType, data) => ({
  type: KIP_SERVER_PERFORMANCE_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});