export const POST_LOGIN_DATA = {'op':'checklogin','serviceName':'login_data'};
export const POST_REGISTER_DATA = {'op':'register','serviceName':'register_data'};
export const POST_VERIFY_EMAIL_DATA = {'op':'verify-register-code','serviceName':'register_data'};
export const POST_UPDATE_PASSWORD_DATA = {'op':'change-password','serviceName':'login_data'};
export const POST_FORGOT_PASSWORD_DATA = {'op':'forget-password','serviceName':'login_data'};
export const POST_VERIFY_CODE_DATA = {'op':'verify-code','serviceName':'login_data'};
export const POST_RESET_PASSWORD_DATA = {'op':'reset-password','serviceName':'login_data'};

export const POST_USER_OUTLET_UPDATE_DATA = {'op':'userCurrentOutletSave','serviceName':'user_data'};

export const GET_USER_INFO_DATA = {'op':'getFrontUserData','serviceName':'common_data'};

export const POST_KPI_DASHBOARD = {
    'serviceName':'kpi_data',
    'filter_op':'kpi_filter',
    'widget_op':'kpi_widget',
    'forecast_op' : 'kpi_forecast',
    'server_performance_op' : 'kpi_server_performance',
    'forecast_heatmap_op' : 'kpi_forecast_heatmap',
};

export const POST_MENU_OPTIMIZATION = {
    'serviceName':'menu_data',
    'selling_item_op' : 'selling_items',
    'item_graph_op' : 'menu_item_graph',
    'food_tribes_op' : 'menu_food_tribes'
};