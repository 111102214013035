import React, {useEffect, useState} from 'react';
import { Switch, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected, AccessRoute } from './AuthProtected';
import { useSelector } from 'react-redux';
const Index = () => {
    //console.log(checkUserRights('menu_engineering'));

    const { userMenu } = useSelector(state => ({
        userMenu: state.UserOutletGroup.userMenu,
    }));
    const [customAuthProtectedRoutes, setCustomAuthProtectedRoutes]=useState([]);
    useEffect(() => {
        if(userMenu!= undefined && userMenu.length>0){
            var routename=authProtectedRoutes.filter(function(route){
                return userMenu.find((menu) => { return menu.right_key==route.right_key }) !== undefined || route.path==='/';
            });
            setCustomAuthProtectedRoutes(routename);
        }
    },[userMenu])
    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
    const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
    return (
        <React.Fragment>
            <Switch>
                <Route path={availablePublicRoutesPaths}>
                    <NonAuthLayout>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <Route
                                    path={route.path}
                                    component={route.component}
                                    key={idx}
                                    exact={true}
                                />
                            ))}
                        </Switch>
                    </NonAuthLayout>
                </Route>

                <Route path={availableAuthRoutesPath}>
                    <AuthProtected>
                        <VerticalLayout>
                            <Switch>
                                {customAuthProtectedRoutes.map((route, idx) => (
                                    <AccessRoute
                                        path={route.path}
                                        component={route.component}
                                        key={idx}
                                        exact={true}
                                    />
                                ))}
                            </Switch>
                        </VerticalLayout>
                    </AuthProtected>
                </Route>
            </Switch>
        </React.Fragment>
    );
};

export default Index;