import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const Basic = ({series,leftText,bottomText,maxXaxis,dataColors}) => {
    var chartScatterBasicColors = getChartColorsArray(dataColors);    
    const options = {
        chart: {
            zoom: {
                enabled: true,
                type: "xy",
            },
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            tickAmount: 8,
            min: 0,
            max:maxXaxis??160,
            title: {
                text: bottomText??"Quantity Sold",                
                style:{
                    colors:['#000000']
                }
            },
        },
        yaxis: {
            title: {
                text: leftText,
                style: {
                    fontWeight: 600,
                },
            },
            tickAmount: 10,
            max:100,
        },
        colors: chartScatterBasicColors,
        legend:{
            showForSingleSeries: true,
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="scatter"
                height={350}
            />
        </React.Fragment>
    );
};

const Datetime = ({series,dataColors}) => {
    var chartScatterDateTimeColors = getChartColorsArray(dataColors);

    const options = {
        chart: {
            zoom: {
                type: "xy",
            },
            toolbar: {
                show: !1,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        grid: {
            xaxis: {
                lines: {
                    show: !0,
                },
            },
            yaxis: {
                lines: {
                    show: !0,
                },
            },
        },
        xaxis: {
            type: "datetime",
        },
        yaxis: {
            max: 100,
        },
        colors: chartScatterDateTimeColors,
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="scatter"
                height={350}
            />
        </React.Fragment>
    );
};

export {
    Basic,
    Datetime
};
