import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Card, CardHeader, CardBody, Table, Row, Col, Input, Label } from 'reactstrap';
import { StaticColorRange } from "../../pages/DashboardEcommerce/HeatmapCharts";
//constants
const Briefing = () => {
    const [modalbriefing, setModalBriefing] = useState(false);
    function togBriefing() {
        setModalBriefing(!modalbriefing);
    }
    const performances=[
        {
            name:"Covers",
            actual:1230,
            mtd_forecast:1245,
            mtd_budget:1100,
            variance_mtd_forecast:-15,
            variance_mtd_budget:130,
            variance_mtd_forecast_per:'-1.2%',
            variance_mtd_budget_per:'11.8%',
            full_month_forecast:3690,
            full_month_budget:3735
        },
        {
            name:"Table Occupancy",
            actual:'72%',
            mtd_forecast:'73%',
            mtd_budget:'70%',
            variance_mtd_forecast:'-1%',
            variance_mtd_budget:'2%',
            variance_mtd_forecast_per:'-1.4%',
            variance_mtd_budget_per:'2.9%',
            full_month_forecast:'77%',
            full_month_budget:'70%'
        },
        {
            name:"Seat Occupancy",
            actual:'76%',
            mtd_forecast:'75%',
            mtd_budget:'80%',
            variance_mtd_forecast:'1%',
            variance_mtd_budget:'-4%',
            variance_mtd_forecast_per:'1.3%',
            variance_mtd_budget_per:'-5.0%',
            full_month_forecast:'75%',
            full_month_budget:'80%'
        },
        {
            name:"Average Check",
            actual:'$105.33',
            mtd_forecast:'$103.70',
            mtd_budget:'$100.00',
            variance_mtd_forecast:'$1.63',
            variance_mtd_budget:'$5.33',
            variance_mtd_forecast_per:'1.6%',
            variance_mtd_budget_per:'5.3%',
            full_month_forecast:'$105.88',
            full_month_budget:'$100.00'
        },
        {
            name:"Revenue",
            actual:'$129,556',
            mtd_forecast:'$129,107',
            mtd_budget:'$110,000',
            variance_mtd_forecast:'$499',
            variance_mtd_budget:'$19,556',
            variance_mtd_forecast_per:'0.3%',
            variance_mtd_budget_per:'17.8%',
            full_month_forecast:'$390,697',
            full_month_budget:'$373,500'
        },
        {
            name:"RevPash",
            actual:'$56.88',
            mtd_forecast:'$57.12',
            mtd_budget:'$55.23',
            variance_mtd_forecast:'$50.24',
            variance_mtd_budget:'$31.65',
            variance_mtd_forecast_per:'-12.40%',
            variance_mtd_budget_per:'63.70%',
            full_month_forecast:'$58.36',
            full_month_budget:'$55.23'
        }
    ];
    const colorCode={
        red:'#f8696b',
        orange:'#fcb479',
        yellow:'#ffeb84',
        lightgreen:'#b9d780',
        green:'#63be7b',
    }
    var day = new Date();
    var week = new Array(
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    );
    let dayList=[];
    for (var i = 0; i <7; i++) {
        dayList.push({
            date:(day.toLocaleString('en-us', { day: '2-digit' }))+' '+(day.toLocaleString('en-us', { month: 'short' })),
            day:week[(day.getDay()) % 7],
        });
        day.setDate(day.getDate() + 1);
    }
    const thisWeekForecast=[
        {
            name:"Covers",
            monday:162,
            monday_bg:"red",
            tuesday:204,
            tuesday_bg:"orange",
            wednesday:234,
            wednesday_bg:"yellow",
            thursday:264,
            thursday_bg:"lightgreen",
            friday:270,
            friday_bg:"lightgreen",
            saturday:300,
            saturday_bg:"green",
            sunday:162,
            sunday_bg:'red',
            total:1596,
            total_bg:'yellow'
        },
        {
            name:"Table Occupancy",
            monday:'54%',
            monday_bg:"red",
            tuesday:'68%',
            tuesday_bg:"orange",
            wednesday:'78%',
            wednesday_bg:"yellow",
            thursday:'88%',
            thursday_bg:"lightgreen",
            friday:'90%',
            friday_bg:"lightgreen",
            saturday:'100%',
            saturday_bg:"green",
            sunday:'54%',
            sunday_bg:'red',
            total:'76%',
            total_bg:'yellow'
        },
        {
            name:"Seat Occupancy",
            monday:'78%',
            monday_bg:"yellow",
            tuesday:'81%',
            tuesday_bg:"lightgreen",
            wednesday:'90%',
            wednesday_bg:"green",
            thursday:'77%',
            thursday_bg:"yellow",
            friday:'65%',
            friday_bg:"red",
            saturday:'65%',
            saturday_bg:"red",
            sunday:'88%',
            sunday_bg:'green',
            total:'77.71%',
            total_bg:'yellow'
        },
        {
            name:"Average Check",
            monday:'$65.00',
            monday_bg:"yellow",
            tuesday:'$70.00',
            tuesday_bg:"lightgreen",
            wednesday:'$55.00',
            wednesday_bg:"orange",
            thursday:'$57.00',
            thursday_bg:"orange",
            friday:'$89.00',
            friday_bg:"green",
            saturday:'$75.00',
            saturday_bg:"lightgreen",
            sunday:'$45.00',
            sunday_bg:'red',
            total:'$65.14',
            total_bg:'yellow'
        },
        {
            name:"Revenue",
            monday:'$10,530',
            monday_bg:"orange",
            tuesday:'$14,280',
            tuesday_bg:"yellow",
            wednesday:'$12,870',
            wednesday_bg:"yellow",
            thursday:'$15,048',
            thursday_bg:"yellow",
            friday:'$24,030',
            friday_bg:"green",
            saturday:'$22,500',
            saturday_bg:"green",
            sunday:'$7,290',
            sunday_bg:'red',
            total:'$106,548',
            total_bg:'yellow'
        },
        {
            name:"RevPash",
            monday:'$21.67',
            monday_bg:"yellow",
            tuesday:'$23.33',
            tuesday_bg:"lightgreen",
            wednesday:'$18.30',
            wednesday_bg:"orange",
            thursday:'$19.00',
            thursday_bg:"orange",
            friday:'$29.67',
            friday_bg:"green",
            saturday:'$25.00',
            saturday_bg:"lightgreen",
            sunday:'$15.00',
            sunday_bg:'red',
            total:'$151.97',
            total_bg:'yellow'
        },
        {
            name:"Forecast Delivery Orders",
            monday:28,
            tuesday:31,
            wednesday:33,
            thursday:54,
            friday:76,
            saturday:98,
            sunday:86,
            total:406
        },
        {
            name:"Average Delivery Check",
            monday:'$36.00',
            tuesday:'$39.00',
            wednesday:'$35.00',
            thursday:'$39.00',
            friday:'$55.00',
            saturday:'$57.00',
            sunday:'$29.00',
            total:'$29.85'
        },
        {
            name:"Delivery Revenue",
            monday:'$1,008',
            tuesday:'$1,209',
            wednesday:'$1,155',
            thursday:'$2,106',
            friday:'$4,180',
            saturday:'$5,586',
            sunday:'$4,214',
            total:'$19,458'
        }
    ];
    var recommendedMenuItemCourse=[
        {
            name:"Smashed Avocado Toast",
            course:"Breakfast",
            mealPeriod:"Breakfast",
            Accept:true,
        },
        {
            name:"Croissant & Coffee combo",
            course:"Breakfast",
            mealPeriod:"Breakfast",
            Accept:false,
        },
        {
            name:"Steak & Chips",
            course:"Main Course",
            mealPeriod:"Lunch",
            Accept:false,
        },
        {
            name:"Apple pie",
            course:"Desert",
            mealPeriod:"Lunch",
            Accept:true,
        },
        {
            name:"Virgin Mojito",
            course:"Non Alcoholic",
            mealPeriod:"Lunch",
            Accept:false,
        },
        {
            name:"Cesar Salad",
            course:"Starter",
            mealPeriod:"Dinner",
            Accept:false,
        },
        {
            name:"Veal Cutlet",
            course:"Main Course",
            mealPeriod:"Dinner",
            Accept:true,
        },
        {
            name:"French fries",
            course:"Starter",
            mealPeriod:"Dinner",
            Accept:true,
        },
        {
            name:"Cheese Cake",
            course:"Side",
            mealPeriod:"Dinner",
            Accept:false,
        },
        {
            name:"lanotti Amore",
            course:"Alcoholic Beverages",
            mealPeriod:"Dinner",
            Accept:false,
        }
    ];
    var recommendedPricing=[
        {
            name:"Chiken Tikka",
            course:"Main Course",
            mealPeriod:"Lunch",
            oldPrice:'$11.00',
            newPrice:'$12.50',
            Accept:true,
        },
        {
            name:"Cheese Cake Coffee Combo",
            course:"Desert",
            mealPeriod:"Lunch",
            oldPrice:'$8.50',
            newPrice:'$7.50',
            Accept:false,
        },
        {
            name:"Sirloin Steak",
            course:"Main Course",
            mealPeriod:"Dinner",
            oldPrice:'$15.00',
            newPrice:'$18.00',
            Accept:false,
        }
    ];
    var serverPerformance=[
        {
            name:"Server 1",
            api:0.9,
            aci:0.8,
            rpi:0.8,
            recommendation:'Focus on Wine',
        },
        {
            name:"Server 2",
            api:0.8,
            aci:1.4,
            rpi:1.2,
            recommendation:'Focus on Deserts',
        },
        {
            name:"Server 3",
            api:1.1,
            aci:0.9,
            rpi:1,
            recommendation:'Focus on Starters',
        }
    ];
    var todayDate=new Date();
    return (
        <>
            <div className="hstack gap-2 flex-wrap">
                <Button color="primary" onClick={() => togBriefing()}> Briefing </Button>
            </div>
            <Modal className="modal-dialog modal-xl custom-bg" isOpen={modalbriefing} toggle={() => { togBriefing(); }} id="fullscreeexampleModal">
                <ModalHeader className="modal-title"
                    id="exampleModalFullscreenLabel" toggle={() => {
                        togBriefing();
                    }}>
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Performance</h4>                
                        </CardHeader>

                        <CardBody>
                            <div className="table-responsive table-card">
                                <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                    <thead className="text-muted table-light">
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">{ (todayDate.toLocaleString('en-us', { day: '2-digit' }))+' '+(todayDate.toLocaleString('en-us', { month: 'short' }))+' '+(todayDate.getFullYear()) }</th>
                                            <th scope="col" colSpan={2}>MTD</th>
                                            <th scope="col" colSpan={2}>Variance MTD</th>
                                            <th scope="col" colSpan={2}>Variance MTD%</th>
                                            <th scope="col" colSpan={2}>Full month</th>
                                        </tr>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Actual</th>
                                            <th scope="col">Forecast</th>
                                            <th scope="col">Budget</th>
                                            <th scope="col">Forecast</th>
                                            <th scope="col">Budget</th>
                                            <th scope="col">Forecast</th>
                                            <th scope="col">Budget</th>
                                            <th scope="col">Forecast</th>
                                            <th scope="col">Budget</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(performances || []).map((item, key) => (<tr key={key}>
                                            <td>{ item.name }</td>
                                            <td>{ item.actual }</td>
                                            <td>{ item.mtd_forecast }</td>
                                            <td>{ item.mtd_budget }</td>
                                            <td>{ item.variance_mtd_forecast }</td>
                                            <td>{ item.variance_mtd_budget }</td>
                                            <td>{ item.variance_mtd_forecast_per }</td>
                                            <td>{ item.variance_mtd_budget_per }</td>
                                            <td>{ item.full_month_forecast }</td>
                                            <td>{ item.full_month_budget }</td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">This week Forecast</h4>                
                        </CardHeader>

                        <CardBody>
                            <div className="table-responsive">
                                <Table className="table-bordered border-dark align-middle table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            {
                                            (dayList).map((day, key) => (
                                                <td key={key}>{ day.date }</td>
                                            ))}
                                            <td>Total Week</td>
                                        </tr>
                                        {(thisWeekForecast || []).map((item, key) => (<tr key={key}>
                                            <td>{ item.name }</td>
                                            {
                                            (dayList).map((day, key2) => (
                                                <td key={key2} style={{ backgroundColor:colorCode[item[day.day.toLowerCase()+'_bg']] }}>{ item[day.day.toLowerCase()] }</td>
                                            ))}
                                            <td style={{ backgroundColor:colorCode[item.total_bg] }}>{ item.total }</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                    <Row>
                  <Col xl={6}>
                    <Card>
                      <div className="card-header px-2">
                        <h4 className="card-title mb-0">
                          Forecast Table Occupancy
                        </h4>
                      </div>

                      <div className="card-body px-2">                        
                        <StaticColorRange forecastHeatmapData={[]}
                            dataText="Average Occ"
                            dataColors='["--vz-danger", "--vz-success"]'
                          />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card>
                      <div className="card-header px-2">
                        <h4 className="card-title mb-0">
                          Forecast Seat Occupancy
                        </h4>
                      </div>

                      <div className="card-body px-2">
                          <StaticColorRange forecastHeatmapData={[]}
                            dataText="Average Occ"
                            showYaxis={false}
                            dataColors='["--vz-danger", "--vz-success"]'
                          />
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Menu Items Recommendations</h4>                
                            </CardHeader>

                            <CardBody>
                                <div className="table-responsive mt-4 mt-xl-0">
                                    <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Meal Period</th>
                                                <th scope="col">Accept</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(recommendedMenuItemCourse || []).map((item, key) => (<tr key={key}>
                                                <td>{ item.name }</td>
                                                <td>{ item.course }</td>
                                                <td>{ item.mealPeriod }</td>
                                                <td>
                                                    <div className="form-check form-switch">
                                                        <Input className="form-check-input" type="checkbox" role="switch" id={`SwitchCheck-${key}`}/>
                                                        <Label className="form-check-label ms-2" htmlFor={`SwitchCheck-${key}`}>Yes/No</Label>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={12}>
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Pricing Recommendations</h4>                
                            </CardHeader>

                            <CardBody>
                                <div className="table-responsive mt-4 mt-xl-0">
                                    <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Pricing recommendations</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Meal Period</th>
                                                <th scope="col">Old Price</th>
                                                <th scope="col">New Price</th>
                                                <th scope="col">Accept</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(recommendedPricing || []).map((item, key) => (<tr key={key}>
                                                <td>{ item.name }</td>
                                                <td>{ item.course }</td>
                                                <td>{ item.mealPeriod }</td>
                                                <td>{ item.oldPrice }</td>
                                                <td>{ item.newPrice }</td>
                                                <td>
                                                    <div className="form-check form-switch">
                                                        <Input className="form-check-input" type="checkbox" role="switch" id={`SwitchCheck-${key}`}/>
                                                        <Label className="form-check-label ms-2" htmlFor={`SwitchCheck-${key}`}>Yes/No</Label>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={12}>
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Server performance</h4>                
                            </CardHeader>

                            <CardBody>
                                <div className="table-responsive mt-4 mt-xl-0">
                                    <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Server name</th>
                                                <th scope="col">API</th>
                                                <th scope="col">ACI</th>
                                                <th scope="col">RPI</th>
                                                <th scope="col">Recommendations</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(serverPerformance || []).map((item, key) => (<tr key={key}>
                                                <td>{ item.name }</td>
                                                <td>{ item.api }</td>
                                                <td>{ item.aci }</td>
                                                <td>{ item.rpi }</td>
                                                <td>{ item.recommendation }</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Briefing;