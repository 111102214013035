import React from "react";
import { Col, Container, Row, Card, CardHeader, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {ColorRange} from "./HeatmapCharts";
import {StackedColumn} from "./ColumnCharts";
import { CardTableExample } from './GridTablesData';
const TableSeatOptimization = () => {
  document.title = "Table Seat Optimization | "+process.env.REACT_APP_NAME;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Table Seat Optimization" pageTitle="Dashboard" />
          <Row>
            <Col>
              <div className="h-100">                
                <Row>
                  <Col xl={4}>
                      <Card>
                          <div className="card-header px-2">
                              <h4 className="card-title mb-0">Table Occupancy</h4>
                          </div>

                          <div className="card-body px-2">
                                <ColorRange dataText="Average Occ" dataColors='["--vz-danger", "--vz-success"]'/>
                          </div>
                      </Card>
                  </Col>
                  <Col xl={4}>
                      <Card>
                          <div className="card-header px-2">
                              <h4 className="card-title mb-0">Seat Occupancy</h4>
                          </div>

                          <div className="card-body px-2">
                              <ColorRange dataText="Average Occ" dataColors='["--vz-danger", "--vz-success"]'/>
                          </div>
                      </Card>
                  </Col>
                  <Col xl={4}>
                      <Card>
                          <div className="card-header px-2">
                              <h4 className="card-title mb-0">RevPASH</h4>
                          </div>

                          <div className="card-body px-2">
                              <ColorRange dataText="RevPASH" dataColors='["--vz-danger", "--vz-success"]'/>
                          </div>
                      </Card>
                  </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Table Mix By Party Size</h4>
                                <div className="flex-shrink-0">
                                  <UncontrolledDropdown className="card-header-dropdown" direction='start'>
                                      <DropdownToggle tag="a" className="text-reset" role="button">
                                        <span className="text-muted">Today<i className="mdi mdi-chevron-down ms-1"></i></span>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem>Today</DropdownItem>
                                          <DropdownItem>Yesterday</DropdownItem>
                                          <DropdownItem>Last 7 Days</DropdownItem>
                                          <DropdownItem>Last 30 Days</DropdownItem>
                                          <DropdownItem>This Month</DropdownItem>
                                          <DropdownItem>Last Month</DropdownItem>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                              </div>
                            </CardHeader>
                            <CardBody>
                                <StackedColumn dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger"]' />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">Table Revenue</h4>
                            </CardHeader>

                            <CardBody>
                                <div id="table-card" className="table-card">
                                    <CardTableExample />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TableSeatOptimization;
