import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import {currencyFormat} from "../../helpers/api_helper";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { getKIPServerPerformanceData } from "../../store/actions";
import Loader from "../../Components/Common/Loader"
const ServerPerformance = () => {

    const dispatch = useDispatch ();

    const { serverPerformances, serverPerformanceAjax} = useSelector(state => ({
        serverPerformances: state.Dashboard.serverPerformanceData,
        serverPerformanceAjax: state.Dashboard.serverPerformanceAjax,
    }));
    useEffect(() => {
        dispatch(getKIPServerPerformanceData({}));
    },[])
    return (
        <React.Fragment>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Server performance</h4>
                    <div className="flex-shrink-0">
                        <div className="input-group">
                            <Flatpickr
                                className="form-control border-0 dash-filter-picker shadow"
                                placeholder="Select Date"
                                options={{
                                    mode: "range",
                                    dateFormat: "d M, Y",
                                }}
                            />
                            <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                        </div>
                    </div>                    
                </CardHeader>

                <CardBody>
                    {
                        serverPerformanceAjax && <Loader />
                    }
                    <div className={serverPerformanceAjax?'opacity-0 table-responsive table-card':'table-responsive table-card'}>
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                            <thead className="text-muted table-light">
                                <tr>
                                    <th scope="col">Covers</th>
                                    <th scope="col">Server Name</th>
                                    <th scope="col">Check Count</th>
                                    <th scope="col">Revenue</th>
                                    <th scope="col">Avg Check Price</th>
                                    <th scope="col">Team Average</th>
                                    <th scope="col">Vaciance %</th>
                                    <th scope="col">Turn Time</th>
                                    <th scope="col">SPI</th>
                                    <th scope="col">ACI</th>
                                    <th scope="col">RPI</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(serverPerformances || []).map((item, key) => (<tr key={key}>
                                    <td>{ item.id }</td>
                                    <td>{ item.name }</td>
                                    <td>{ item.check_count }</td>
                                    <td>
                                        <span className="text-success">{currencyFormat(item.revenue)}</span>
                                    </td>
                                    <td><span className="text-warning">{currencyFormat(item.avgCheck)}</span></td>
                                    <td>{currencyFormat(item.teamAvg)}</td>
                                    <td>{item.vaciance}</td>
                                    <td>{item.turnTime}</td>
                                    <td>{item.spi}</td>
                                    <td>{item.aci}</td>
                                    <td>{item.rpi}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ServerPerformance;