import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback,Alert, Spinner } from 'reactstrap';
import ParticlesAuth from './ParticlesAuth';
import logoLight from "../../assets/images/logo-light.png";
import { verifyEmail, updatePassword } from "../../store/actions";
//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

const PasswordUpdate = (props) => {
    const {verifycode}=useParams()
    const dispatch = useDispatch();
    
    document.title = "Password Update | "+process.env.REACT_APP_NAME;

    const [passwordShow, setPasswordShow] = useState(false);
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);

    useEffect(() => {
        dispatch(verifyEmail(verifycode));
    }, [verifycode,dispatch]);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            old_password:"",
            password: "",
            confrim_password: "",
        },
        validationSchema: Yup.object({
            old_password: Yup.string().required("This field is required"),
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required("This field is required"),
            confrim_password: Yup.string()
                .when("password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Both password need to be the same"
                    ),
                })
                .required("Confirm Password Required"),
        }),
        onSubmit: (values) => {
            values.email=userEmail;
            dispatch(updatePassword(values,props.history));
        }
    });

    const { error, verifyEmailError, success, loading, userEmail } = useSelector(state => ({
        verifyEmailError: state.VerifyEmail.message,
        success: state.VerifyEmail.success,
        error: state.VerifyEmail.error,
        loading:state.VerifyEmail.loading,
        userEmail:state.VerifyEmail.user
    }));
    const { updateerror, updatePasswordError, isApiLoading } = useSelector(state => ({
        updateerror: state.UpdatePassword.error,
        updatePasswordError: state.UpdatePassword.message,
        isApiLoading:state.UpdatePassword.loading,
    }));
    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-4 mb-4 text-white-50">
                                <div>
                                    <Link to="/#" className="d-inline-block auth-logo">
                                    <img src={logoLight} className="img-fluid" alt={process.env.REACT_APP_SMALL_NAME} width="180"/>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">

                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Update password</h5>
                                        <p className="text-muted">Your new password must be different from previous used password.</p>
                                    </div>
                                    
                                    <div className="p-2">
                                        { loading && <div className="text-center"><Spinner color="primary"> Loading... </Spinner></div> }
                                        {error && verifyEmailError ? (<Alert color="danger"> {verifyEmailError.message} </Alert>) : null}
                                        {updateerror && updatePasswordError ? (<Alert color="danger"> {updatePasswordError.message} </Alert>) : null}
                                        
                                        { success && userEmail && <Form onSubmit={validation.handleSubmit}>
                                            
                                            <div className="mb-3">
                                                <Label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></Label>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email address"
                                                    type="email"
                                                    value={userEmail || ""}
                                                    disabled
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                ) : null}

                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password-input">Old Password <span className="text-danger">*</span></Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={oldPasswordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        placeholder="Enter old password"
                                                        id="old_password"
                                                        name="old_password"
                                                        value={validation.values.old_password}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.old_password && validation.touched.old_password ? true : false}
                                                    />
                                                    {validation.errors.old_password && validation.touched.old_password ? (
                                                        <FormFeedback type="invalid">{validation.errors.old_password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setOldPasswordShow(!oldPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                        id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                                <div id="passwordInput" className="form-text">Must be at least 8 characters.</div>
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password-input">Password <span className="text-danger">*</span></Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={passwordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        placeholder="Enter password"
                                                        id="password-input"
                                                        name="password"
                                                        value={validation.values.password}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.password && validation.touched.password ? true : false}
                                                    />
                                                    {validation.errors.password && validation.touched.password ? (
                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                        id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                                <div id="passwordInput" className="form-text">Must be at least 8 characters.</div>
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm-password-input">Confirm Password <span className="text-danger">*</span></Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        type={confrimPasswordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        placeholder="Confirm password"
                                                        id="confirm-password-input"
                                                        name="confrim_password"
                                                        value={validation.values.confrim_password}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.confrim_password && validation.touched.confrim_password ? true : false}
                                                    />
                                                    {validation.errors.confrim_password && validation.touched.confrim_password ? (
                                                        <FormFeedback type="invalid">{validation.errors.confrim_password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button">
                                                        <i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>

                                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                <h5 className="fs-13">Password must contain:</h5>
                                                <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                                <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                                <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                                <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                            </div>

                                            <div className="mt-4">
                                                <Button color="success" className="w-100" type="submit" disabled={isApiLoading}>Reset Password <Spinner className={isApiLoading ? "ms-1" : "ms-1 invisible"} size="sm"></Spinner></Button>
                                            </div>
                                        </Form> }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};
export default PasswordUpdate;