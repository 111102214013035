import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//ResetPassword Redux states
import { RESET_PASSWORD } from "./actionTypes";
import { resetPasswordSuccessful, resetPasswordFailed } from "./actions";

//Include Both Helper File with needed methods
import { postDataAPI } from "../../../helpers/phpbackend_helper";

import {POST_RESET_PASSWORD_DATA} from "../../../helpers/data_helper";
import {saveLoggedinUser} from "../../../helpers/api_helper";
let md5 = require('md5');

// Is user register successfull then direct plot user in redux.
function* resetPassword({ payload: { data, history } }) {
  try {
    const response = yield call(postDataAPI, {
      npwd: md5(data.password),
      cpwd: md5(data.confrim_password),
      reg_ver_code: data.reg_ver_code,
      op:POST_RESET_PASSWORD_DATA.op,
      serviceName:POST_RESET_PASSWORD_DATA.serviceName
    });
    
    if (response.status === "ok") {
      saveLoggedinUser(JSON.stringify(response));
      yield put(resetPasswordSuccessful(response));
      history.push("/dashboard");      
    } else {
      yield put(resetPasswordFailed(response));
    }
  } catch (error) {
    yield put(resetPasswordFailed(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* resetPasswordSaga() {
  yield all([fork(watchResetPassword)]);
}

export default resetPasswordSaga;
