import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
const Navdata = () => {
    const history = useHistory();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    //console.log(iscurrentState);
    const [menuItems, setMenuItems] = useState([]);

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }
    

    const { userMenu } = useSelector(state => ({
        userMenu: state.UserOutletGroup.userMenu,
    }));
    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Admin') {
            setIsAdmin(false);
        }
        let customMenuItem=[{
            label: "Menu",
            isHeader: true,
        }];
       if(userMenu!= undefined && userMenu.length>0){
          userMenu.map(function(menu){
            if(menu.right_parent==0){
                let meniItem={
                    id: menu.right_key,
                    label: menu.right_name,
                    icon: menu.icon??"ri-dashboard-2-line",
                    link: menu.file_name,
                };
                let subMenu=userMenu.filter(submenu => submenu.right_parent==menu.right_id);
                if(subMenu.length>0){
                    meniItem.link='/#';
                    meniItem.click=function (e) {
                        e.preventDefault();
                        setIsAdmin(!isAdmin);
                        setIscurrentState('Admin');
                        updateIconSidebar(e);
                    };
                    meniItem.subItems=[];
                    meniItem.stateVariables=isAdmin;
                    subMenu.map(function(smenu){
                        meniItem.subItems.push({
                            id: smenu.right_key,
                            label: smenu.right_name,
                            link: smenu.file_name,
                            parentId: menu.right_key,
                        })
                    });
                }
                customMenuItem.push(meniItem);
            }
          });
       }
       setMenuItems(customMenuItem);
    }, [
        history,
        iscurrentState,
        isDashboard,
        isAdmin,
        isAuth,
        userMenu
    ]);
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;