import React, { useState, useEffect} from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Table,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import itemCategory from "../../assets/images/item-category.jpg";
import { Basic } from "./ScatterCharts";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import BestSellingProducts from "../DashboardEcommerce/BestSellingProducts";
import Loader from "../../Components/Common/Loader";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";

const MenuEngineering = () => {
  document.title = "Menu Engineering | " + process.env.REACT_APP_NAME;
  const [defaultCourse, setDefaultCourse] = useState("Main Course");  
  const [leftFilter, setLeftFilter] = useState("ThisMonth");
  const [rightFilter, setRightFilter] = useState(false);
  const [mealPeriod, setMealPeriod] = useState("All");
  const [ordersTime, setOrdersTime] = useState(false);
  const [comparisonDate, setComparisonDate] = useState(null);
  const [ordersTimeComparison, setOrdersTimeComparison] = useState([]);
  const [filtermealperiod, setFiltermealperiod] = useState([]);
  const [filtercourses, setFilterCourses] = useState([]);
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [menuItemData, setMenuItemData] = useState({Courses:[],MenuItems:[]});
  const [menuItemAjax, setMenuItemAjax] = useState(true);
  const [foodTribeData, setFoodTribeData] = useState({"AvgCheckPY":[],"AvgCheckTY":[],"CoversPY":[],"CoversTY":[],"CourseName":[]});
  const [foodTribeAjax, setFoodTribeAjax] = useState(true);
  const foodItemsSeries = menuItemData.Courses.filter(function (foodItem) {
    return defaultCourse === "All"
      ? true
      : foodItem.name === defaultCourse;
  });
  
  let foodBeverageSeries = menuItemData.MenuItems[defaultCourse]??[];
  if(defaultCourse=='All'){
    Object.keys(menuItemData.MenuItems).map((key) => {
      foodBeverageSeries=[...foodBeverageSeries,...menuItemData.MenuItems[key]];
    })
  }
  const ShowModelPopupToggle = () => {
    setShowModelPopup(!showModelPopup);
  };
  const changeDefaultComparison = (item) => {
    setRightFilter(item);
  };
  const changeLeftFilter = (value) => {
    setLeftFilter(value);
  };
  const changeMealPeriod = (value) => {
    setMealPeriod(value);
  };

  const {
    filterData,
    filterAjax
  } = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
  }));
  
    
  useEffect(() => {
    if (filterData.date) {
        setOrdersTime(filterData.date);
    }
    if (filterData.date_compare) {
        setOrdersTimeComparison(filterData.date_compare);
    }
    if (filterData.mealperiod) {
        setFiltermealperiod(filterData.mealperiod);
    }
    if (filterData.courses) {
      setFilterCourses(filterData.courses);
  }
  }, [filterAjax, filterData]);
  useEffect(() => {
    postDataAPI({serviceName:POST_MENU_OPTIMIZATION.serviceName,op:POST_MENU_OPTIMIZATION.item_graph_op}).then(response => {
      setMenuItemAjax(false)
      if(response.status=='ok'){
        setMenuItemData(response.data);
      }else{
        console.log(response);
      }
    });
    
    postDataAPI({serviceName:POST_MENU_OPTIMIZATION.serviceName,op:POST_MENU_OPTIMIZATION.food_tribes_op}).then(response => {
      setFoodTribeAjax(false)
      if(response.status=='ok'){
        setFoodTribeData(response.data);
      }else{
        console.log(response);
      }
    });
  },[])
  var chartLineColumnColors = getChartColorsArray('["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning"]');
  const fooedTribesSeries= [
      {
          name: "Covers TY",
          type: "column",
          data: foodTribeData["CoversTY"],
      },
      {
          name: "Covers PY",
          type: "column",
          data: foodTribeData["CoversPY"],
      },
      {
          name: "Avg Check TY",
          type: "line",
          data: foodTribeData["AvgCheckTY"],
      },
      {
          name: "Avg Check PY",
          type: "line",
          data: foodTribeData["AvgCheckPY"],
      },
  ];
  const fooedTribesOptions = {
      chart: {
        toolbar: {
          show: !1,
        },
      },
      stroke: {
        width: [0, 0, 4,4],
        curve: "smooth",
      },
      title: {
        text: ''
      },
      dataLabels: {
        enabled: true,
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          if(seriesIndex<=1){
            return value+'%';
          }else{
            return process.env.REACT_APP_CURRENCY_SIGN+value;
          }
        },
      },
      labels: foodTribeData["CourseName"],
      xaxis: {
        type: "category",
      },
      yaxis: [
        {
          title: {
            seriesName: "Covers TY",
            text: "Revenue Forecast (USS)",
            style: {
              fontWeight: 600,
            },
          },
        },
        {          
          seriesName: "Covers PY",
          show: false,
        },
        {
          opposite: true,
          seriesName: "Avg Check TY",
          title: {
            text: "",
            style: {
              fontWeight: 600,
            },
          },
        },
        {
          show: false,          
          seriesName: "Avg Check PY",
        },
      ],
      colors: chartLineColumnColors,
      plotOptions: {
          bar: {
              columnWidth: "40%",
          },
      },
  };
  const activeComparisonData = ordersTimeComparison.find(
    (time) => rightFilter.value === time.value
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Menu Optimization" pageTitle="Dashboard" />
          <form className="mb-3">
            <Row>
              <Col className="mb-2 mb-lg-0" sm={12} lg={3}>
                <select
                  className="form-select"
                  value={leftFilter}
                  id="leftFilter"
                  onChange={(event) => changeLeftFilter(event.target.value)}
                >
                  {!filterAjax &&
                    ordersTime.length > 0 &&
                    ordersTime.map((time) => {
                      return (
                        <option key={time.value} value={time.value}>
                          {time.label}
                        </option>
                      );
                    })}
                </select>
              </Col>
              <Col className="mb-2 mb-lg-0" sm={12} lg={3}>
                <UncontrolledDropdown>
                  <DropdownToggle
                    type="button"
                    tag="button"
                    className="btn btn-outline-custom"
                  >
                    {rightFilter ? rightFilter.label : "Select Comparison"}
                    <span className="rightBox">
                      <span></span>
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        className="css-tj5bde-Svg"
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="custom-menu-width" end={true}>
                    <Row>
                      <Col
                        sm={
                          rightFilter &&
                          activeComparisonData &&
                          activeComparisonData.datepiker
                            ? 4
                            : 12
                        }
                      >
                        {!filterAjax &&
                          ordersTimeComparison.length > 0 &&
                          ordersTimeComparison.map((item) => {
                            return (
                              <button
                                key={item.value}
                                onClick={() => changeDefaultComparison(item)}
                                type="button"
                                tabIndex="0"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                {item.label}
                              </button>
                            );
                          })}
                      </Col>
                      {rightFilter &&
                        activeComparisonData &&
                        activeComparisonData.datepiker && (
                          <Col sm={8}>
                            <Flatpickr
                              className="form-control border-0 dash-filter-picker shadow mw-auto"
                              placeholder=""
                              value={comparisonDate}
                              onChange={([date, date2]) => {
                                setComparisonDate([date, date2]);
                              }}
                              options={{
                                mode: "range",
                                dateFormat: "d M, Y",
                                inline: true,
                              }}
                            />
                          </Col>
                        )}
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col sm={12} lg={3}>
                <select
                  className="form-select"
                  value={mealPeriod}
                  id="mealPeriod"
                  onChange={(event) => changeMealPeriod(event.target.value)}
                >
                  {!filterAjax &&
                    filtermealperiod.length > 0 &&
                    filtermealperiod.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
              </Col>
              <Col sm={12} lg={3}>
                <select
                  className="form-select"
                  value={defaultCourse}
                  id="mealPeriod"
                  onChange={(event) => setDefaultCourse(event.target.value)}
                >
                  {!filterAjax &&
                    filtercourses.length > 0 &&
                    filtercourses.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
              </Col>
            </Row>
          </form>

          <Row>
            <Col lg={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Course</h4>
                </CardHeader>
                <CardBody>
                  { menuItemAjax && <Loader />}
                  <div className={menuItemAjax ? "opacity-0" : ""}>
                    <Basic
                      series={foodItemsSeries}
                      leftText="Contribution Margin"
                      dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info"]'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Menu Items</h4>
                </CardHeader>
                <CardBody>
                  { menuItemAjax && <Loader />}
                  <div className={menuItemAjax ? "opacity-0" : ""}>
                    <Basic
                      series={foodBeverageSeries}
                      leftText="Contribution Margin"
                      dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Food Tribes</h4>
                </CardHeader>
                <CardBody>
                  { foodTribeAjax && <Loader />}
                  <div className={foodTribeAjax ? "opacity-0" : ""}>
                    <ReactApexChart
                        options={fooedTribesOptions}
                        series={fooedTribesSeries}
                        type="line"
                        height={350}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <BestSellingProducts />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={showModelPopup}
        toggle={() => {
          ShowModelPopupToggle();
        }}
        centered
      >
        <ModalHeader className="justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              ShowModelPopupToggle();
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody>
          <img src={itemCategory} className="img-fluid" />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default MenuEngineering;
