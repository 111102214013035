import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_MENU_SELLING_ITEM_API_DATA,
  MENU_SELLING_ITEM_API_RESPONSE_SUCCESS
} from "./actionType";
import {
  dashboardApiMenuSellingItemSuccess
} from "./action";

//Include Both Helper File with needed methods
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";

function* getMenuSellingItemData({ payload: data }) {
  try {
    data.serviceName = POST_MENU_OPTIMIZATION.serviceName;
    data.op = POST_MENU_OPTIMIZATION.selling_item_op;
    var response = yield call(postDataAPI, data);
    if (response.status == "ok") {
      yield put(
        dashboardApiMenuSellingItemSuccess(
          MENU_SELLING_ITEM_API_RESPONSE_SUCCESS,
          response.data
        )
      );
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchGetMenuData() {
  yield takeEvery(GET_MENU_SELLING_ITEM_API_DATA, getMenuSellingItemData);
}

function* menuOptimizationSaga() {
  yield all([fork(watchGetMenuData)]);
}

export default menuOptimizationSaga;
