import {
  GET_MENU_SELLING_ITEM_API_DATA,
  MENU_SELLING_ITEM_API_RESPONSE_SUCCESS
} from "./actionType";

export const getMenuSellingItemData = (data) => ({
  type: GET_MENU_SELLING_ITEM_API_DATA,
  payload: data
});

export const dashboardApiMenuSellingItemSuccess = (actionType, data) => ({
  type: MENU_SELLING_ITEM_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});