import {
  GET_KIP_WIDGET_API_DATA,
  KIP_FILTER_API_RESPONSE_SUCCESS,
  KIP_WIDGET_API_RESPONSE_SUCCESS,
  GET_KIP_FORECAST_API_DATA,
  KIP_FORECAST_API_RESPONSE_SUCCESS,
  GET_KIP_SERVER_PERFORMANCE_API_DATA,
  KIP_SERVER_PERFORMANCE_API_RESPONSE_SUCCESS,
  GET_KIP_FORECAST_HEATMAP_API_DATA,
  KIP_FORECAST_HEATMAP_API_RESPONSE_SUCCESS
} from "./actionType";

const INIT_STATE = {
  filterData: [],
  filterAjax:true,
  widgetData: [],
  widgetAjax:true,
  forecastData: {
    revenue:[],
    covers:[],
    revenue_cp:[],
    covers_cp:[],
    seat_occupancy:[],
    seat_occupancy_cp:[],
    average_check:[],
    average_check_cp:[],
    revPASH:[],
    revPASH_cp:[],
    revenue_max:0,
    covers_max:0,
    seat_occupancy_max:0,
    average_check_max:0,
    revPASH_max:0,
  },
  forecastAjax:true,
  serverPerformanceData: [],
  serverPerformanceAjax:true,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case KIP_FILTER_API_RESPONSE_SUCCESS:
      return {
        ...state,
        filterData: action.payload.data,
        filterAjax:false,
      };
    case GET_KIP_WIDGET_API_DATA:
      return {
        ...state,
        widgetAjax:true,
      };
    case KIP_WIDGET_API_RESPONSE_SUCCESS:
      return {
        ...state,
        widgetData: action.payload.data,
        widgetAjax:false,
      };
    case GET_KIP_FORECAST_API_DATA:
      return {
        ...state,
        forecastAjax:true,
      };
    case KIP_FORECAST_API_RESPONSE_SUCCESS:
      return {
        ...state,
        forecastData: action.payload.data,
        forecastAjax:false,
      };
    case GET_KIP_SERVER_PERFORMANCE_API_DATA:
      return {
        ...state,
        serverPerformanceAjax:true,
      };
    case KIP_SERVER_PERFORMANCE_API_RESPONSE_SUCCESS:
      return {
        ...state,
        serverPerformanceData: action.payload.data,
        serverPerformanceAjax:false,
      };
    default:
      return state;
  }
};
export default Dashboard;