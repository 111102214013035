import React from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import ServerPerformanceTable from "./ServerPerformance";
var chartLineAreaMultiColors = getChartColorsArray('["--vz-primary", "--vz-success", "--vz-danger"]');
const revenueSeries = [
    {
        name: "Revenue",
        type: "column",
        data: [1505, 4200, 3500, 3450, 1700],
    },
    {
        name: "Server Average Check",
        type: "line",
        data: [240, 440, 430, 500, 300],
    },
    {
        name: "Team Average",
        type: "line",
        data: [448, 448, 448, 448, 448],
    }
];
const revenueOptions = {
    chart: {
        stacked: !1,
        toolbar: {
            show: !1,
        },
    },
    stroke: {
        width: [0,3, 3],
        curve: 'smooth',
        dashArray: [0, 0, 5]
    },
    plotOptions: {
        bar: {
            columnWidth: "40%",
        },
    },
    labels: [
        "Server 1",
        "Server 2",
        "Server 3",
        "Server 4",
        "Server 5"
    ],
    markers: {
        style: 'inverted',
        size: 4
    },
    xaxis: {
        type: "category",
    },
    yaxis: [
        {
            title: {
                text: "Server Revenue"
            },
        },
        {
            opposite: true,
            title: {
                text: "Average Check"
            },
        }
    ],
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function (y) {
                if (typeof y !== "undefined") {
                    return y.toFixed(0) + " points";
                }
                return y;
            },
        },
    },
    colors: chartLineAreaMultiColors,
};

const turnTimeSeries = [
    {
        name: "Turn Time",
        type: "column",
        data: [70, 80, 87, 87, 78],
    },
    {
        name: "Server Average Check",
        type: "line",
        data: [240, 440, 430, 500, 300],
    },
    {
        name: "Team Average",
        type: "line",
        data: [448, 448, 448, 448, 448],
    }
];
const turnTimeOptions = {
    chart: {
        stacked: !1,
        toolbar: {
            show: !1,
        },
    },
    stroke: {
        width: [0,3, 3],
        curve: 'smooth',
        dashArray: [0, 0, 5]
    },
    plotOptions: {
        bar: {
            columnWidth: "40%",
        },
    },
    labels: [
        "Server 1",
        "Server 2",
        "Server 3",
        "Server 4",
        "Server 5"
    ],
    markers: {
        style: 'inverted',
        size: 4
    },
    xaxis: {
        type: "category",
    },
    yaxis: [
        {
            title: {
                text: "Server Turn Time"
            },
        },
        {
            opposite: true,
            title: {
                text: "Average Check"
            },
        }
    ],
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function (y) {
                if (typeof y !== "undefined") {
                    return y.toFixed(0) + " points";
                }
                return y;
            },
        },
    },
    colors: chartLineAreaMultiColors,
};

var chartStackedBarColors = getChartColorsArray('["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info"]');
const serverSeries = [{
    name: 'Starters',
    data: [12, 16, 22, 15, 16]
}, {
    name: 'Main Course',
    data: [28, 38, 30, 50, 32]
},{
    name: 'Side',
    data: [16, 10, 28, 22, 12]
}, {
    name: 'Dessert',
    data: [10, 9, 12, 8, 8]
}, {
    name: 'Alc Bev',
    data: [12, 14, 16, 10, 15]
}, {
    name: 'Non-Alc Bev',
    data: [12, 4, 8, 6, 10]
}];
var serverOptions = {
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
            show: false,
        }
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    title: {
        text: '',
    },
    xaxis: {
        categories: ['Server 5', 'Server 4', 'Server 3', 'Server 2', 'Server 1'],
        labels: {
            formatter: function (val) {
                return val + "K";
            }
        }
    },
    yaxis: {
        title: {
            text: undefined
        },
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + "K";
            }
        }
    },
    fill: {
        opacity: 1
    },
    legend: {
        position: 'bottom',
        horizontalAlign: 'left',
        offsetX: 40
    },
    colors: chartStackedBarColors,
};

const filterEmployees = [
    { label: "Employee 1", value: "Employee 1" },
    { label: "Employee 2", value: "Employee 2" },
    { label: "Employee 3", value: "Employee 3" },
    { label: "Employee 4", value: "Employee 4" },
    { label: "Employee 5", value: "Employee 5" },
    { label: "Employee 6", value: "Employee 6" },
];
const filtermealperiod = [
    { label: "All", value: "All" },
    { label: "8AM-9AM", value: "8AM-9AM" },
    { label: "9AM-10AM", value: "9AM-10AM" },
    { label: "10AM-11AM", value: "10AM-11AM" },
    { label: "11AM-12PM", value: "11AM-12PM" },
    { label: "12PM-01PM", value: "12PM-01PM" },
    { label: "02PM-03PM", value: "02PM-03PM" },
    { label: "03PM-04PM", value: "03PM-04PM" },
    { label: "08PM-09PM", value: "08PM-09PM" },
    { label: "09PM-10PM", value: "09PM-10PM" },
    { label: "10PM-11PM", value: "10PM-11PM" },
    { label: "11PM-12AM", value: "11PM-12AM" },
  ];

const ServerPerformance = () => {
    document.title = "Server Performance | " + process.env.REACT_APP_NAME;
    return (
    <React.Fragment>
        <div className="page-content">
        <Container fluid>
            <BreadCrumb title="Server Performance" pageTitle="Dashboard" />
            <Row className="mb-4 justify-content-sm-end">
                <Col sm={6} lg={3}>
                    <Select
                    options={filterEmployees}
                    name="choices-single-default"
                    id="idEmployee"
                    placeholder="Select Employee"
                    ></Select>
                </Col>
                <Col sm={6} lg={3}>
                    <Select
                    options={filtermealperiod}
                    name="choices-single-default"
                    id="idMealPeriod"
                    placeholder="Select Meal period"
                    ></Select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ServerPerformanceTable />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Server Revenue</h4>
                        </CardHeader>

                        <CardBody>
                            <ReactApexChart
                                options={revenueOptions}
                                series={revenueSeries}
                                type="line"
                                height={350}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Server Turn Time</h4>
                        </CardHeader>

                        <CardBody>
                            <ReactApexChart
                                options={turnTimeOptions}
                                series={turnTimeSeries}
                                type="line"
                                height={350}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Server</h4>
                        </CardHeader>

                        <CardBody>
                            <ReactApexChart
                                options={serverOptions}
                                series={serverSeries}
                                type="bar"
                                height={350}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
    </React.Fragment>
    );
};

export default ServerPerformance;
