import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col
} from "reactstrap";
import { currencyFormat } from "../../helpers/api_helper";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getMenuSellingItemData } from "../../store/actions";
import itemCategory from "../../assets/images/item-category.jpg";
import Loader from "../../Components/Common/Loader";
import { Basic } from "../MenuEngineering/ScatterCharts";

const foodItems = [
    {
        name: "Steak and Chips",
        data: [[80, 88]],
    },
    {
        name: "Veal Cutlet",
        data: [[150, 75]],
    },
    {
        name: "Pasta Bolognese",
        data: [[40, 90]],
    },
    {
        name: "Pizza Salami",
        data: [[38, 80]],
    },
    {
        name: "Burger and Chips",
        data: [[120, 80]],
    },
    {
        name: "Pot-au-feu",
        data: [[34, 88]],
    },
];
const beverageItems = {
    "Pot-au-feu":[
        {
            name: "Salade niçoise",
            data: [[80, 35]],
        },
        {
            name: "Anchoiade",
            data: [[85, 87]],
        },
        {
            name: "Bouchée à la reine",
            data: [[45, 95]],
        },
        {
            name: "Creme Brulee",
            data: [[90, 45]],
        },
        {
            name: "Mille-Feuille",
            data: [[60, 88]],
        },
        {
            name: "Chateau Latour (2010)",
            data: [[30, 60]],
        },
        {
            name: "Chateau Lafite Rothschild (2016)",
            data: [[95, 20]],
        }
    ]
}

const BestSellingProducts = () => {
  const dispatch = useDispatch();
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [modalcollaboration, setModalCollaboration] = useState(false);
  const [course, setCourse] = useState('Main Course');
  const [dishe, setDishe] = useState('Pot-au-feu');
  const { sellingItem, sellingItemAjax } = useSelector((state) => ({
    sellingItem: state.MenuOptimization.sellingItemData,
    sellingItemAjax: state.MenuOptimization.sellingItemAjax,
  }));
  const ShowModelPopupToggle = () => {
    setShowModelPopup(!showModelPopup);
  };
  const togCollaboration= () => {
    setModalCollaboration(!modalcollaboration);
  }
  useEffect(() => {
    dispatch(getMenuSellingItemData({}));
  }, []);
  const profitContributionSort = (rowA, rowB) => {
    if (rowA.profit_contribution > rowB.profit_contribution) {
        return 1;
    }
    if (rowB.profit_contribution > rowA.profit_contribution) {
        return -1;
    }
    return 0;
  };
  const popularityContributionSort = (rowA, rowB) => {
    if (rowA.popularity_contribution > rowB.popularity_contribution) {
        return 1;
    }
    if (rowB.popularity_contribution > rowA.popularity_contribution) {
        return -1;
    }
    return 0;
  };
  const performanceGroupSort = (rowA, rowB) => {
    if (rowA.performance_group > rowB.performance_group) {
        return 1;
    }
    if (rowB.performance_group > rowA.performance_group) {
        return -1;
    }
    return 0;
  };
  const columns = [
    {
      id: "name",
      name: <span className="font-weight-bold fs-13">Item</span>,
      selector: (row) => row.name,
      sortable: false,
      width: "250px"
    },
    {
      id: "sold",
      name: <span className="font-weight-bold fs-13">Sold</span>,
      selector: (row) => row.sold,
      sortable: true,
    },
    {
      id: "sell_price",
      name: <span className="font-weight-bold fs-13">Sell Price</span>,
      sortable: true,
      selector: (row) => currencyFormat(row.sell_price),
    },
    {
      id: "item_cost",
      name: <span className="font-weight-bold fs-13">Item Cost</span>,
      selector: (row) => currencyFormat(row.item_cost),
      sortable: true,
    },
    {
      id: "item_gross_profit",
      name: (
        <span className="font-weight-bold fs-13">
          Item Gross <br />
          Profit
        </span>
      ),
      selector: (row) => currencyFormat(row.item_gross_profit),
      sortable: true,
    },
    {
      id: "sales_mix",
      name: <span className="font-weight-bold fs-13">Sales Mix %</span>,
      selector: (row) => row.sales_mix + "%",
      sortable: true,
    },
    {
      id: "total_cost",
      name: <span className="font-weight-bold fs-13">Total Cost</span>,
      selector: (row) => currencyFormat(row.total_cost),
      sortable: true,
    },
    {
      id: "total_sales",
      name: <span className="font-weight-bold fs-13">Total Sales</span>,
      sortable: true,
      selector: (row) => currencyFormat(row.total_sales),
    },
    {
      id: "total_gross_profit",
      name: (
        <span className="font-weight-bold fs-13">
          Total Gross <br />
          Profit
        </span>
      ),
      sortable: true,
      selector: (row) => currencyFormat(row.total_gross_profit),
    },
    {
      id: "profit_contribution",
      name: (
        <span className="font-weight-bold fs-13">
          Profit <br />
          Contribution
        </span>
      ),
      sortable: true,
      selector: (cell) => {
        switch (cell.profit_contribution) {
          case "Low":
            return (
              <span
                className="badge badge-soft-danger"
              > {cell.profit_contribution}</span>
            );
          default:
            return (
              <span
                className="badge badge-soft-success"
              > {cell.profit_contribution}</span>
            );
        }
      },      
      sortFunction: profitContributionSort
    },
    {
      id: "popularity_contribution",
      name: (
        <span className="font-weight-bold fs-13">
          Popularity <br />
          Contribution
        </span>
      ),
      sortable: true,
      selector: (cell) => {
        switch (cell.popularity_contribution) {
          case "Low":
            return (
              <span
                className="badge badge-soft-danger"
              > {cell.popularity_contribution}</span>
            );
          default:
            return (
              <span
                className="badge badge-soft-success"
              > {cell.popularity_contribution}</span>
            );
        }
      },
      sortFunction: popularityContributionSort
    },
    {
      id: "performance_group",
      name: (
        <span className="font-weight-bold fs-13">
          Performance <br />
          Group
        </span>
      ),
      sortable: true,
      sortFunction:performanceGroupSort,
      selector: (cell) => {
        switch (cell.performance_group) {
          case "Star":
            return (
              <span
                onClick={ShowModelPopupToggle}
                className="badge bg-info"
              > {cell.performance_group}</span>
            );
          case "Dogs":
            return (
              <span
                onClick={ShowModelPopupToggle}
                className="badge bg-warning"
              > {cell.performance_group}</span>
            );
          case "Cash Cow":
            return (
                <span
                onClick={ShowModelPopupToggle}
                className="badge bg-danger"
                > {cell.performance_group}</span>
            );
          default:
            return (
              <span
                onClick={ShowModelPopupToggle}
                className="badge bg-success"
              > {cell.performance_group}</span>
            );
        }
      },
    }
  ];
  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
     const aField = Number(selector(rowA).toString().replace(/[^0-9-]+/g,""))/100
     const bField = Number(selector(rowB).toString().replace(/[^0-9-]+/g,""))/100
    
     let comparison = 0;
   
     if (aField > bField) {
      comparison = 1;
     } else if (aField < bField) {
      comparison = -1;
     }
   
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };
    const handlerCourse = (value) => {
        setCourse(value)
    }
    const handlerDishe = (value) => {
        setDishe(value)
    }
    const courseList = [
        {
            value:'Starters',
            label:'Starters',
        },
        {
            value:'Main Course',
            label:'Main Course',
        },
        {
            value:'Sides',
            label:'Sides',
        },
        {
            value:'Desserts',
            label:'Desserts',
        }
    ];
    const allDishesList = {
        "Starters":[
            {
                value:'Tempura',
                label:'Tempura',
            },
            {
                value:'Carpacio',
                label:'Carpacio',
            },
            {
                value:'Soup',
                label:'Soup',
            },
            {
                value:'Civeche',
                label:'Civeche',
            },
        ],
        "Main Course":[
            {
                value:'Steak and Chips',
                label:'Steak and Chips',
            },
            {
                value:'Veal Cutlet',
                label:'Veal Cutlet',
            },
            {
                value:'Pasta Bolognese',
                label:'Pasta Bolognese',
            },
            {
                value:'Pizza Salami',
                label:'Pizza Salami',
            },
            {
                value:'Burger and Chips',
                label:'Burger and Chips',
            },
            {
                value:'Pot-au-feu',
                label:'Pot-au-feu',
            }
        ],
        "Sides":[            
            {
                value:'Skewer',
                label:'Skewer',
            },
            {
                value:'Oyester',
                label:'Oyester',
            }
        ],
        "Desserts":[            
            {
                value:'Peach Cobbler',
                label:'Peach Cobbler',
            },
            {
                value:'Vanilla Gelato',
                label:'Vanilla Gelato',
            },

        ],
    }
    const dishesList=allDishesList[course];
    const foodItemsSeries = foodItems.filter(function (foodItem) {
        return dishe === "All"
          ? true
          : foodItem.name === dishe;
      });
    const filterBeverageItems=beverageItems[dishe]??[];

  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Selling Items</h4>
          <div className="flex-shrink-0">
            <Button color="primary" onClick={togCollaboration}> Correlation </Button>
          </div>
        </CardHeader>
        <CardBody>
          {sellingItemAjax && <Loader />}
          <div className={sellingItemAjax ? "opacity-0" : ""}>
            <DataTable
              columns={columns}
              data={sellingItem}
              defaultSortFieldId="sold"
              defaultSortAsc={false}
              sortFunction={customSort}
              pagination
            />
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={showModelPopup}
        toggle={() => {
          ShowModelPopupToggle();
        }}
        centered
      >
        <ModalHeader className="justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              ShowModelPopupToggle();
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody>
          <img src={itemCategory} className="img-fluid" />
        </ModalBody>
      </Modal>

      <Modal className="modal-dialog modal-xl modal-xxl custom-bg" isOpen={modalcollaboration} toggle={() => { togCollaboration(); }} id="modalcollaboration">
            <ModalHeader className="modal-title"
                id="modalcollaborationLabel" toggle={() => {
                    togCollaboration();
                }}>
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                <Col className="mb-2 mb-lg-0" sm={12} lg={6}>
                  <select
                    className="form-select"
                    value={course}
                    id="course"
                    onChange={(event) => handlerCourse(event.target.value)}
                  >
                    {
                        courseList.map((time) => {
                            return (
                                <option key={time.value} value={time.value}>
                                {time.label}
                                </option>
                            );
                        })
                    }
                  </select>
                </Col>
                <Col className="mb-2 mb-lg-0" sm={12} lg={6}>
                  <select
                    className="form-select"
                    value={dishe}
                    id="course"
                    placeholder="Select Course"
                    onChange={(event) => handlerDishe(event.target.value)}
                  >
                    <option value="">Select Dish</option>
                    {
                        dishesList.map((time) => {
                            return (
                                <option key={time.value} value={time.value}>
                                {time.label}
                                </option>
                            );
                        })
                    }
                  </select>
                </Col>
            </Row>
            <Row>
            <Col lg={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">{ dishe }</h4>
                </CardHeader>
                <CardBody>
                  <Basic
                    series={foodItemsSeries}
                    leftText="Contribution Margin"
                    bottomText="Quantity sold"
                    dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info"]'
                  />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Menu Items</h4>
                </CardHeader>
                <CardBody>
                  <Basic
                    series={filterBeverageItems}
                    bottomText="% Correlation"
                    leftText="Contribution Margin"
                    maxXaxis={100}
                    dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
            </ModalBody>
        </Modal>
    </>
  );
};

export default BestSellingProducts;
