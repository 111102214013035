import {
  GET_MENU_SELLING_ITEM_API_DATA,
  MENU_SELLING_ITEM_API_RESPONSE_SUCCESS
} from "./actionType";

const INIT_STATE = {
  sellingItemData: [],
  sellingItemAjax:true,
};

const MenuOptimization = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MENU_SELLING_ITEM_API_DATA:
      return {
        ...state,
        sellingItemAjax:true,
      };
    case MENU_SELLING_ITEM_API_RESPONSE_SUCCESS:
      return {
        ...state,
        sellingItemData: action.payload.data,
        sellingItemAjax:false,
      };
    default:
      return state;
  }
};
export default MenuOptimization;