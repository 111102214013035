import axios from "axios";
import CryptoJS from 'crypto-js';
// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};


function _0x1b3e(_0x2b7975,_0x1b4451){var _0x1b3e9e=_0x1b44();_0x1b3e=function(_0x2f38ef,_0x1299ca){_0x2f38ef=_0x2f38ef-0x10c;var _0x146752=_0x1b3e9e[_0x2f38ef];return _0x146752;};return _0x1b3e(_0x2b7975,_0x1b4451);}function encode(_0x626419){var _0x46f03d=_0x1b3e;var _0xb60972='';_0x626419=window[_0x46f03d(0x10f)](_0x626419);_0x626419=window[_0x46f03d(0x10f)](_0x626419);for(let _0x5e379b=0x0;_0x5e379b<_0x626419[_0x46f03d(0x10e)];_0x5e379b++){var _0x4c5bee=_0x626419[_0x46f03d(0x10c)](_0x5e379b);var _0x3d1643=_0x4c5bee^0x6c;_0xb60972=_0xb60972+String[_0x46f03d(0x10d)](_0x3d1643);}_0xb60972=window[_0x46f03d(0x10f)](_0xb60972);return _0xb60972;}function _0x1b44(){var _0x331c8e=['charCodeAt','fromCharCode','length','btoa'];_0x1b44=function(){return _0x331c8e;};return _0x1b44();}

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };

  get = (url, params) => {
    let response;

    let paramKeys = [];
    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    data.clid=process.env.REACT_APP_CLID;
    data.clientName=process.env.REACT_APP_CLIENT_NAME;
    const userInfo=getLoggedinUser();
    if(userInfo && userInfo.user_id){
      data.app_user_id=userInfo.user_id;
    }
    return axios.post(url, process.env.REACT_APP_ALLOWDEBUGG==='1'?data:{'param':encode(JSON.stringify(data))});
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    data.clid=process.env.REACT_APP_CLID;
    data.clientName=process.env.REACT_APP_CLIENT_NAME;
    const userInfo=getLoggedinUser();
    if(userInfo && userInfo.user_id){
      data.app_user_id=userInfo.user_id;
    }
    return axios.patch(url, process.env.REACT_APP_ALLOWDEBUGG==='1'?data:{'param':encode(JSON.stringify(data))});
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = getLocalStorageData('authUser');
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const saveLoggedinUser = (user) => {
  setLocalStorageData('authUser',user);
};

const getLocalStorageData = (name) => { 
  var ld = localStorage.getItem('app_data');	
	if (ld) {
		var strld = CryptoJS.AES.decrypt(ld, '$ang$0ft@2020').toString(CryptoJS.enc.Utf8);
		var objld = JSON.parse(strld);
    return objld[name]; 
	}
	return null;
}

const setLocalStorageData = (name, value) =>{
    if (localStorage.getItem('app_data') === null) {
        var objld = {};
        objld[name]=value;
        strld =JSON.stringify(objld);
    } else {
        var ld = localStorage.getItem('app_data');
        var strld = CryptoJS.AES.decrypt(ld, '$ang$0ft@2020').toString(CryptoJS.enc.Utf8); 
        var objld = JSON.parse(strld);
        objld[name]=value;
        strld =JSON.stringify(objld);
    }
    localStorage.setItem('app_data',CryptoJS.AES.encrypt(strld, '$ang$0ft@2020').toString());
}

const checkUserRights = (rightKey) => {
  let user=getLoggedinUser();
  console.log(user.user_rights);
  var userRights = user.user_rights;
  userRights = userRights == null ? "" : userRights;
  return (userRights.search(rightKey + ";") != -1);

}
const currencyFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: process.env.REACT_APP_CURRENCY??'USD'
  }).format(value);

export { APIClient, setAuthorization, getLoggedinUser, setLocalStorageData, getLocalStorageData, saveLoggedinUser, checkUserRights, currencyFormat};